import MagentoUrlBuilder from '@themes/magento/frontend/services/UrlBuilder';
import _startsWith       from 'lodash/startsWith';

const BLOG_PATH = "/blog";

class UrlBuilder extends MagentoUrlBuilder{

  getRoutesList(){
    const magentoRoutes = super.getRoutesList();

    return {
      ...magentoRoutes,
      'newAccount':     { href: '/new-account',              as: '/new-account'},
      'cmsPage':        { href: '/cms_page\\?id=:id',        as: '/:slug'},
      'ethicalChannel': { href: '/ethical-channel',          as: '/ethical-channel'},
    };
  }

  getBlogUrl(slug=''){
    slug = _startsWith(slug, '/') ? slug : '/' + slug;
    return this.getBaseUrl() + BLOG_PATH + slug;
  }
}

export default UrlBuilder;
