import Router           from 'next/router'
import checkServer      from '@frontend/util/checkServer'
import _forEach         from 'lodash/forEach';
import _get             from 'lodash/get';
import { pathToRegexp } from "path-to-regexp";
import settings         from '@frontend/settings/url-builder'

const DEFAULT_CONFIG = {
  currentStore:{
    path:''
  }
}

class UrlBuilder {

  constructor(config={}){
    this.config = {...DEFAULT_CONFIG,...config};
    this.get    = this.getRoute;
    this.redirect = this.redirect.bind(this);
  }

  redirect(key,context={},params){
    const { href,as } = this.getRoute(key,params);

    if (checkServer() && context && context.res) {
      context.res.writeHead(303, { Location: as })
      context.res.end()
    } else {
      // In the browser, we just pretend like this never even happened ;)
      Router.replace(href,as).then(() => window.scrollTo(0, 0));
    }
  }

  goToExternalUrl(url){
    window.location.href = url;
    //Router.replace(url);
  }

  getCurrentUrl(){
    if (!checkServer() && window && window.location) {
      return window.location.href;
    }

    return '';
  }

  getCurrentPath(){
    if (!checkServer() && window && window.location) {
      return window.location.pathname;
    }

    return '';
  }

  static getUrlParams(string){
    if (!!string || (!checkServer() && window && window.location.search)) {
      return (string || window.location.search).replace(/(^\?)/,'').split("&").map(function(n){return n = n.split("="),this[n[0]] = n[1],this}.bind({}))[0];
    }

    return '';
  }

  getUrlParams(string){
    return this.constructor.getUrlParams(string)
  }

  getRoutesList(){
    return {
      '':             { href: '',                         as: ''  },
      '/':            { href: '/',                        as: '/' },
      'custom':       { href: '/:slug',                   as: '/:slug'},
      'searchResult': { href: '/search-result\\?q=:slug', as: '/search-result\\?q=:slug' },
      '404':          { href: '/404',                     as:'/404'}
    };
  }

  replacePathKeys = (path,keys,params) => {
    // remove backslash
    path = path.replace(/\\/g,'');

    for(let {name} of keys){
      path = path.replace(`:${name}`, params[name])
    }

    return path;
  }

  addSubpath(route){
    const { currentStore:{ path } } = this.config;

    return (path + route).replace(/\/\//g,'/');
  }

  getRoute(key,params={}){
    const routes      = this.getRoutesList();
    const { href,as } = routes[key] || {href:key,as:key};
    let asKeys        = [];
    let hrefKeys      = [];

    pathToRegexp(as,   asKeys);
    pathToRegexp(href, hrefKeys);

    let asRoute   = this.replacePathKeys(as,  asKeys,   params);
    let hrefRoute = this.replacePathKeys(href,hrefKeys, params);
    asRoute       = this.addSubpath(asRoute);

    return {
      as:   asRoute,
      href: hrefRoute
    };
  }

  webpUrl(url){
    return url.includes('?') ? `${url}&auto=format` : `${url}?auto=format`;
  }

  /**
   * Method to change url without reloading
   * 
   * @param string url 
   */
   changeUrlWithoutReloading(url){
    window.history.replaceState(null, null, url);
  }


  /**
   * Method to add url attributes without reloading
   * 
   * @param object attributes 
   */
   addAttributesUrlWithoutReloading(attributes){
    if(!!_get(attributes, 'length')){
      let params = '?';
      _forEach(attributes, ({attributeCode, attributeValue}, index) => {
        params = params.concat(`${index > 0 ? '&' : ''}${attributeCode}=${attributeValue}`);
      });
      const url = this.getCurrentPath() + params;
      this.changeUrlWithoutReloading(url);
    }
  }


  /**
   * Return src image with url imgix prefix
   * @param {String} src 
   * @returns 
   */
  getCdnImageUrl(src){
    return `${_get(settings,'imgix.url')}${src}`;
  } 

}
export default UrlBuilder;
