import BaseUrlBuilder from '@themes/base/frontend/services/UrlBuilder';
import _get           from 'lodash/get';

class UrlBuilder extends BaseUrlBuilder{

  getRoutesList(){
    const baseRoutes = super.getRoutesList();

    return {
      ...baseRoutes,
      'myAccountAddAddress':  { href: '/my-account/add/address',              as: '/my-account/add/address'},
      'myAccountEditAddress': { href: '/my-account/edit/address\\?id=:id',    as: '/my-account/edit/address\\?id=:id'},
      'myAccountEditCustomer':{ href: '/my-account/edit/customer',            as: '/my-account/edit/customer'},
      'myAccountOrdersOrder': { href: '/my-account/orders/order',             as: '/my-account/orders/order'},
      'myAccountOrder':       { href: '/my-account/order\\?id=:id',           as: '/my-account/order/:id'},
      'myAccountMe':          { href: '/my-account/me',                       as: '/my-account/me'},
      'myAccountMyAddresses': { href: '/my-account/my-addresses',             as: '/my-account/my-addresses'},
      'myAccountMyOrders':    { href: '/my-account/my-orders',                as: '/my-account/my-orders'},
      'myAccountWishlist':    { href: '/my-account/wishlist',                 as: '/my-account/wishlist'},
      'searchProducts':       { href: '/search-result\\?q=:q',                as: '/search-result\\?q=:q'},
      'category':             { href: '/catalog_category\\?id=:id',           as: '/:slug'},
      'product':              { href: '/catalog_product\\?id=:id',            as: '/:slug'},
      'cmsPage':              { href: '/cms_page\\?id=:id',                   as: '/:slug'},
      'contact':              { href: '/contact',                             as: '/contact'},
      'createAccount':        { href: '/create-account',                      as: '/create-account'},
      'forgottenPassword':    { href: '/forgotten-password',                  as: '/forgotten-password'},
      'me':                   { href: '/me',                                  as: '/me'},
      'resetPassword':        { href: '/reset-password',                      as: '/reset-password'},
      'signOut':              { href: '/sign-out',                            as: '/sign-out'},
      'signin':               { href: '/signin',                              as: '/signin'}
    };
  }

  getBaseUrl(){
    return `${this.config.currentStore.domain}${this.config.currentStore.path}`;
  }

  getUrlFromPath(path,useBasePath=true){
    return useBasePath ? `${this.getBaseUrl()}${path}` : `${this.config.currentStore.domain}${path}`;
  }

  getUrlFromRoute({href,as}){
    return this.getUrlFromPath(as,false);
  }

  getStoreProductUrl(storeConfiguration,params){
    return this.get("product", params);
  }

  getStoreCategoryUrl(storeConfiguration,params){
    return this.get("category", params);
  }

  getStorePath(path){
    return `${this.config.currentStore.path}${path}`;
  }

  getStoreSelection(){
    return _get(this.config,'currentStore.storeSelectionUrl');
  }

  getAlternateUrlFromRoute(storeConfig,path,type=""){
    path = this.correctedPath(path)
    return `${storeConfig.domain}${storeConfig.path}${path}`;
  }

  correctedPath(path){
    path = path.startsWith("/") ? path : `/${path}`;
    path = path === "/home"     ? "/"  : path;
    return path;
  }
}

export default UrlBuilder;
