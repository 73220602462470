import StoreUrlBuilder from '@themes/store/frontend/services/UrlBuilder';

class UrlBuilder extends StoreUrlBuilder{

  getRoutesList(){
    const storeRoutes = super.getRoutesList();

    return {
      ...storeRoutes,
      '/':            { href: '/home',          as: '/'},
      'checkoutCart': { href: '/checkout/cart', as: '/checkout/cart'}
    };
  }

  getStoreProductUrl(storeConfiguration,params){
    let route = this.get("product", params);
    return `${this.getUrlFromRoute(route)}${(storeConfiguration.productUrlSuffix ? storeConfiguration.productUrlSuffix : "")}`;
  }

  getStoreCategoryUrl(storeConfiguration,params){
    let route = this.get("category", params);
    return `${this.getUrlFromRoute(route)}${(storeConfiguration.categoryUrlSuffix ? storeConfiguration.categoryUrlSuffix : "")}`;
  }
  
}

export default UrlBuilder;
